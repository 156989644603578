import { observable, action, runInAction, computed } from 'mobx'

import Logger from 'src/utils/Logger'
import { APIResponse } from 'src/types/APITypes'
import API from 'src/utils/API'
import { ApiRoutes } from 'src/utils/Urls'
import ClientsListModel, {
  type IOfficeListModel,
  type ListResponse
} from 'src/models/Offices/ListModel'
import { toast } from 'react-toastify'
import alertMessages from 'src/constants/alertMessages'

export type ToolboxOptions =
  | ''
  | 'toprec'
  | 'gamification'
  | 'website'
  | 'talent_board'
  | 'ats_linked'
  | 'refari'

type ListParams = {
  ordering?: string
  search?: string
  page?: number
}

type SortConfig = {
  param: string
  direction: boolean // true means ascending order
}

type Filters = Partial<ListParams>

type Pagination = {
  totalRecordCount: number
  currentPage: number
  totalPageCount: number
  pageSize: number
}

type SetPaginationParams = {
  totalRecordCount?: number
  currentPage?: number
  totalPageCount?: number
  pageSize?: number
}

export type IOfficeStore = {
  isFetchingClients: boolean
  offices: Array<IOfficeListModel>
  pagination: Pagination
  shouldShowPagination: boolean
  sortConfig: SortConfig
  search: string
  fetchOffices(isForceRefreshCalculate?: boolean): Promise<void>
  setPaginationParams: (paginationParams: SetPaginationParams) => void
  filterOffices: (filters: Filters) => Promise<void>
  sortList: (sortKey: string) => Promise<void>
  deleteOffice: (id: string) => Promise<void>
  getOfficeDetails: (id: string) => IOfficeListModel | null
}

class OfficeStore implements IOfficeStore {
  @observable isFetchingClients = false
  @observable offices: IOfficeStore['offices'] = []
  @observable pagination: IOfficeStore['pagination'] = {
    totalRecordCount: 0,
    currentPage: 0,
    totalPageCount: 0,
    pageSize: 0
  }
  @observable sortConfig: IOfficeStore['sortConfig'] = {
    param: '',
    direction: false
  }
  @observable search = ''
  @observable toolbox: ToolboxOptions = ''

  @action
  private setIsFetchingClients = (value: boolean) => {
    this.isFetchingClients = value
  }

  @computed
  private get getParams(): ListParams {
    const params: ListParams = {}
    if (this.search) {
      params.search = this.search
    }

    if (this.sortConfig.param) {
      params.ordering = this.sortConfig.direction
        ? this.sortConfig.param
        : `-${this.sortConfig.param}`
    }

    if (this.pagination.currentPage) {
      params.page = this.pagination.currentPage
    }

    return params
  }

  @action
  fetchOffices: IOfficeStore['fetchOffices'] = async (
    isForceRefreshCalculate
  ) => {
    try {
      this.setIsFetchingClients(true)

      const params = isForceRefreshCalculate
        ? { ...this.getParams, force_calculate: true }
        : this.getParams

      const response: APIResponse<ListResponse> = await API.getData(
        ApiRoutes.dashboard.offices.list,
        params
      )

      if (!response.data) {
        throw new Error("FetchClients API Response:: doesn't contain data")
      }

      const clientsResponseResults = response.data.results

      runInAction(() => {
        this.offices = clientsResponseResults.map(
          (item) => new ClientsListModel(item)
        )
        /**
         * @TODO default 0 value setting in here is not safe
         */
        this.setPaginationParams({
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        })
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setIsFetchingClients(false)
    }
  }

  @action
  filterOffices: IOfficeStore['filterOffices'] = async (filters) => {
    if (typeof filters.search !== 'undefined') {
      this.search = filters.search
    }

    if (
      typeof filters.page !== 'undefined' &&
      this.pagination.currentPage !== filters.page
    ) {
      this.pagination.currentPage = filters.page
    }

    this.fetchOffices()
  }

  @action
  setPaginationParams: IOfficeStore['setPaginationParams'] = ({
    totalRecordCount,
    currentPage,
    totalPageCount,
    pageSize
  }) => {
    if (totalRecordCount) {
      this.pagination.totalRecordCount = totalRecordCount
    }

    if (currentPage) {
      this.pagination.currentPage = currentPage
    }

    if (totalPageCount) {
      this.pagination.totalPageCount = totalPageCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
  }

  @action
  sortList: IOfficeStore['sortList'] = async (sortKey: string) => {
    this.pagination.currentPage = 1

    if (this.sortConfig.param === sortKey) {
      /**
       * change the direction
       */
      this.sortConfig = {
        param: sortKey,
        direction: !this.sortConfig.direction
      }
    } else {
      this.sortConfig = {
        param: sortKey,
        direction: false
      }
    }

    this.fetchOffices()
  }

  @action
  deleteOffice: IOfficeStore['deleteOffice'] = async (id) => {
    try {
      const response = await API.deleteData(
        ApiRoutes.dashboard.offices.detail(id)
      )
      if (response.status !== 204) throw new Error('something went wrong')
      toast.success(alertMessages.office.deleteSuccess)
      this.fetchOffices()
    } catch (error) {
      Logger.error(error as any)
      toast.error('something went wrong')
    }
  }

  @action
  getOfficeDetails: IOfficeStore['getOfficeDetails'] = (id) => {
    console.log('id', id)
    const office = this.offices.find((item) => item.id === Number(id))
    console.log('office', office)
    if (office) return office
    return null
  }

  @computed
  get shouldShowPagination(): boolean {
    return this.pagination.totalRecordCount > this.pagination.pageSize
  }
}

export default new OfficeStore()
