/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type CommunicationIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const OfficeIcon = ({
  iconColor,
  ...restProps
}: CommunicationIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...restProps}>
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1396"
          data-name="Rectangle 1396"
          width="24"
          height="24"
          transform="translate(-2.063 -0.698)"
          fill={iconColor}
          stroke={iconColor}
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Communication"
      transform="translate(2.063 0.698)"
      clipPath="url(#clip-path)"
    >
      <path
        id="Icon"
        d="M15.974,19.994H0V16.736a4.492,4.492,0,0,1,.139-.607,6.026,6.026,0,0,1,.6-1.375,4.92,4.92,0,0,1,1.326-1.472,5.024,5.024,0,0,1,2.327-.9c1.217-.018,2.459-.027,3.692-.027,2.1,0,3.463.027,3.476.027a4.123,4.123,0,0,1,.616.1,5.445,5.445,0,0,1,1.4.526,4.523,4.523,0,0,1,1.494,1.3,5.169,5.169,0,0,1,.912,2.427c.049,2.98.016,3.251,0,3.263h0S15.974,19.994,15.974,19.994ZM9.152,13.921l-.586,1.136.642,4.224h.838l1.1-6.205h-6.3l1.08,6.205h.825l.589-4.224-.6-1.136ZM2.3,5.912A5.8,5.8,0,0,1,7.98,0a5.8,5.8,0,0,1,5.682,5.912A5.8,5.8,0,0,1,7.98,11.825,5.8,5.8,0,0,1,2.3,5.912Z"
        transform="translate(-0.063 -0.698)"
        fill={iconColor}
      />
    </g>
  </SvgIcon>
)

export default OfficeIcon
