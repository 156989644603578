import React from 'react'
import PropTypes from 'prop-types'
import { ListSubheader, IconButton } from '@material-ui/core'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import 'react-phone-number-input/style.css'
import {
  isValidPhoneNumber,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber
} from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import greenTick from '../../../assets/images/green_tick.svg'
import redCross from '../../../assets/images/red_cross.svg'
import Field from '../../../models/form/Field'
import RootStore from '../../../stores/RootStore'
import CloseIcon from '@material-ui/icons/Close'

const styles = {
  subheader: {
    paddingLeft: '0',
    paddingTop: '9px',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px'
  }
}

@observer
class Input extends React.Component {
  static propTypes = {
    fieldData: PropTypes.instanceOf(Field).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    multiline: PropTypes.bool,
    className: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    showTicks: PropTypes.bool,
    hintStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    underlineFocusStyle: PropTypes.object,
    rows: PropTypes.number,
    style: PropTypes.object,
    errorStyle: PropTypes.object
  }

  static defaultProps = {
    type: 'text',
    className: '',
    style: {},
    errorStyle: {},
    showTicks: false
  }

  constructor(props) {
    super(props)

    let phoneCode = ''
    let userLocation = ''
    let phoneNumber = ''
    let update = false
    let showCustomTicks = false
    if (props.fieldData && props.fieldData.value) {
      const parsePhoneNumberData = parsePhoneNumber(props.fieldData.value)
      if (parsePhoneNumberData && parsePhoneNumberData.country) {
        phoneCode = `+${parsePhoneNumberData.countryCallingCode}`
        userLocation = parsePhoneNumberData.country
        phoneNumber = parsePhoneNumberData.nationalNumber
        update = true
        if (props.showTicks) {
          showCustomTicks = true
        }
      }
    }

    this.state = {
      errorText: '',
      update,
      phoneCode,
      userLocation,
      phoneNumber,
      showCustomTicks
    }
  }

  async componentDidMount() {
    await RootStore.setLocation()
    this.updateState()
  }

  updateState = () => {
    const { update } = this.state
    if (!update && RootStore.userLocation) {
      this.setState({
        update: true,
        phoneCode: `+${getCountryCallingCode(RootStore.userLocation)}`,
        userLocation: RootStore.userLocation
      })
    }
  }

  onChange = (event) => {
    const { fieldData } = this.props
    const { phoneCode } = this.state
    const val = event.target.value || undefined
    let value = val ? val.trim() : ''
    value = value ? value.replace(/[^0-9 ]+/g, '') : ''
    if (value && value.startsWith('0') && value.length > 2) {
      value = value.replace(/^0+/, '')
    }
    value = value && value.length > 15 ? value.slice(0, 15) : value

    fieldData.update(phoneCode + (value || ''))
    this.setState({ errorText: '', phoneNumber: value || '' })
  }

  restrict = (eventInstance) => {
    const { target } = eventInstance
    if (target && target.value.length > 14) {
      if (eventInstance.preventDefault) {
        eventInstance.preventDefault()
      }
      eventInstance.returnValue = false
      return false
    }
    const key = eventInstance.keyCode || eventInstance.which
    if ((key > 47 && key < 58) || key === 8 || key === 32) {
      return true
    }
    if (eventInstance.preventDefault) eventInstance.preventDefault()
    eventInstance.returnValue = false
    return false
  }

  handleBlur = () => {
    const { cb, fieldData, showTicks } = this.props
    const { phoneNumber } = this.state
    if (
      fieldData.value &&
      fieldData.value.length &&
      phoneNumber &&
      !isValidPhoneNumber(fieldData.value)
    ) {
      this.setState({
        errorText: 'Please use correct format for phone number.'
      })
      cb && cb(true)
    } else {
      this.setState({ errorText: '' })
      cb && cb(false)
    }
    if (showTicks) {
      this.setState({ showCustomTicks: true })
    }
  }

  handleFocus = () => {
    const { showTicks } = this.props
    if (showTicks) {
      this.setState({ showCustomTicks: false })
    }
  }

  setCountry = (value) => {
    if (value) {
      const { phoneNumber } = this.state
      const { cb, fieldData } = this.props
      const phoneCode = `+${getCountryCallingCode(value)}`
      fieldData.update(phoneCode + (phoneNumber || ''))
      this.setState({ phoneCode, userLocation: value })
      cb && cb(false)

      if (phoneNumber) {
        this.handleBlur()
      }
    }
  }

  UNSAFE_componentWillMount() {
    this.updateState()
  }

  handleDeletePhone = () => {
    const { onDelete, fieldData } = this.props
    // const { phoneNumber } = this.state
    fieldData.update('')
    this.setState({ phoneNumber: '', phoneCode: '', userLocation: '' })
    onDelete()
  }

  render() {
    const { fieldData, label, className, required, onDelete } = this.props
    const {
      errorText,
      update,
      showCustomTicks,
      userLocation,
      phoneNumber,
      phoneCode
    } = this.state
    const { serverErrors, clientErrors, isBlurred, isDirty } = fieldData
    const errors = serverErrors.length
      ? serverErrors
      : isDirty && isBlurred && clientErrors
    if (!update) {
      return null
    }

    return (
      <div
        className={`tel-input ${className}`}
        style={{ marginTop: 5, marginBottom: 10 }}
      >
        <ListSubheader
          style={{ ...styles.subheader, width: '100%' }}
          disableSticky
        >
          {label}
          {required && (
            <sup key="required" className="required">
              {' '}
              *
            </sup>
          )}
        </ListSubheader>
        <div className="refari-tel-input-section">
          <div className="react-phone-number-input PhoneInput">
            <div className="PhoneInputCountry" style={{ marginTop: '6px' }}>
              <select
                className="PhoneInputCountrySelect"
                value={userLocation}
                onChange={(event) => this.setCountry(event.target.value || '')}
              >
                {getCountries().map((country) => (
                  <option key={country} value={country}>
                    {en[country]} +{getCountryCallingCode(country)}
                  </option>
                ))}
              </select>
              <div className="PhoneInputCountryIcon PhoneInputCountryIcon--border">
                <div className="tel-code-wrap">
                  <p className="country-code">{phoneCode}</p>
                </div>
              </div>
              <div className="PhoneInputCountrySelectArrow" />
            </div>
            <input
              type="tel"
              autoComplete="off"
              name="phone"
              placeholder="XXXX XXXX"
              className="PhoneInputInput"
              value={phoneNumber || ''}
              onKeyPress={(e) => this.restrict(e)}
              onChange={this.onChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
            />
            {showCustomTicks && phoneNumber && (
              <div
                className={
                  (errors && errors.length > 0) || errorText
                    ? 'react-phone-number-input-right-block react-phone-number-input-right-block-error'
                    : 'react-phone-number-input-right-block'
                }
              >
                <span className="react-phone-number-input-right-icon">
                  <img
                    src={
                      (errors && errors.length > 0) || errorText
                        ? redCross
                        : greenTick
                    }
                    alt="tick"
                  />
                </span>
              </div>
            )}
            {onDelete && phoneNumber && (
              <IconButton
                aria-label="delete"
                onClick={this.handleDeletePhone}
                size="small"
                style={{
                  backgroundColor: 'transparent',
                  position: 'absolute',
                  right: 32,
                  top: 8
                  // width: 12,
                  // height: 12
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </div>
        {(errors[0] || errorText) && (
          <p className="refari-error-text">{errors[0] || errorText}</p>
        )}
      </div>
    )
  }
}

export default withTheme(Input)
