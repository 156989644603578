import { RefariDTOTypes } from '@refari-frontend/types'

export type ListResponse =
  RefariDTOTypes['/dashboard/company-clients/']['get']['responses']['200']['content']['application/json']

export type IOfficeListModel = ListResponse['results'][0]

class OfficeListModel implements IOfficeListModel {
  id: IOfficeListModel['id']
  name: IOfficeListModel['name']
  logo: IOfficeListModel['logo']
  primary_contact: IOfficeListModel['primary_contact']
  primary_workplace_address: IOfficeListModel['primary_workplace_address']
  modified: IOfficeListModel['modified']

  constructor(data: IOfficeListModel) {
    this.id = data.id
    this.name = data.name
    this.logo = data.logo
    this.primary_contact = data.primary_contact
    this.primary_workplace_address = data.primary_workplace_address
    this.modified = data.modified
  }

  get companyLogo(): string {
    if (!this.logo) return ''
    return this.logo.image
  }

  get contactName(): string {
    if (!this.primary_contact) return ''
    return `${this.primary_contact?.first_name} ${this.primary_contact?.last_name}`
  }

  get contactNumber(): string {
    if (!this.primary_contact?.phone) return ''
    return this.primary_contact.phone
  }
}

export default OfficeListModel
