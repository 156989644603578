/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, observable, runInAction, computed } from 'mobx'
import { toast } from 'react-toastify'
import moment from 'moment'
import { RefariDTOTypes } from '@refari-frontend/types'

import API from 'src/utils/API'
import standAloneStore from './StandAloneStore'
import candidatesMainStore from './CandidatesMainStore'
import alertMessages from 'src/constants/alertMessages'
import Logger from 'src/utils/Logger'
import { ApiRoutes } from 'src/utils/Urls'
import { CandidateAdCreateSchema } from 'src/types/APITypes'
import type {
  APIResponse,
  CandidateAdsListResponse,
  CandidateAdsDetailsSchema
} from 'src/types/APITypes'
import CandidateAdsListModel, {
  type ICandidateAdsListModel
} from 'src/models/CandidateAds/ListModel'
import CandidateAdsDetailsModel, {
  type ICandidateAdsDetailsModel
} from 'src/models/CandidateAds/DetailsModel'
import EnquiriesModel, { IEnquiriesModel } from 'src/models/Enquiries'
import {
  generateErrorMessageFromFieldErrors,
  mapFormServerErrors,
  wrapIframesWithDiv
} from 'src/utils/helpers'

export type ListTypeFilterValues = 'active' | 'draft' | 'expired'

type ListParams = {
  ordering?: string
  search?: string
  page?: number
  referrer?: string
  candidate_id?: string
  list_type?: string
  own_only?: boolean
  toolbox?: string
  // has_images?: boolean
  // has_videos?: boolean
}

type Pagination = {
  totalRecordCount: number
  currentPage: number
  totalPageCount: number
  pageSize: number
}

type SetPaginationParams = {
  totalRecordCount?: number
  currentPage?: number
  totalPageCount?: number
  pageSize?: number
}

type CreateCandidateAdAdditionalArgs = {
  candidateId: number
  selecetedKeysGetter: (
    selectedList: Array<any>,
    entireList: Array<any>
  ) => Array<any>
  canAddCandidate: boolean
  candidateAdMode?: CandidateAdMode
  candidateDraftId?: string
  cb?: () => void
}

type CreateCandidateDraftAdAdditionalArgs = {
  candidateId?: number | string
  selecetedKeysGetter: (
    selectedList: Array<any>,
    entireList: Array<any>
  ) => Array<any>
  candidateAdId?: string
  // candidateAdMode?: CandidateAdMode
  cb?: () => void
}

type UpdateCandidateAdAdditionalArgs = {
  selecetedKeysGetter: (
    selectedList: Array<any>,
    entireList: Array<any>
  ) => Array<any>
}

type SortConfig = {
  param: string
  direction: boolean // true means ascending order
}

type Filters = Partial<ListParams>

type CandidateInfoDisplayState = {
  showInfo: boolean
  triggerSource?: 'auto' | 'manual'
}

type CandidateInfoFromPartnerActions = {
  id?: number
  firstName: string
  lastName: string
  email: string
  externalID: string
}

type CandidateAdMode = 'create' | 'edit' | 'repost' | 'draft'

type OpenEditCandidateAdArgs = {
  candidateAdMode: CandidateAdMode
}

export type AIGeneratedCandidateAdResponse =
  RefariDTOTypes['/dashboard/candidate-ads/generate-ai/']['post']['responses']['200']['content']['application/json']

export type UploadCandidateResumeResponse =
  RefariDTOTypes['/dashboard/files/resume/']['post']['responses']['201']['content']['application/json']

export type GetCandidateFiles =
  RefariDTOTypes['/dashboard/candidate-ads/files/']['post']['responses']['200']['content']['application/json']

export type CandidateFileType = Exclude<
  GetCandidateFiles['files_data'],
  undefined
>[0] & {
  isUploaded?: boolean
}

export type LanguagePreference =
  RefariDTOTypes['/dashboard/languages/']['get']['responses']['200']['content']['application/json'][0]

type CustomPreferenceListResponse =
  RefariDTOTypes['/dashboard/candidate-ads/ai-custom-preferences/']['get']['responses']['200']['content']['application/json']
export type CustomPreference = NonNullable<CustomPreferenceListResponse>[0]

type CreateCustomPreferenceAdditionalArgs = {
  cb?: () => void
}

type CustomPreferenceAction = 'create' | 'edit' | null

type SelectedCustomPreferenceId = number | null

type SelectedCustomPreferenceToGenerateAIAd = CustomPreference | null
type UpdateCustomPreferenceAdditionalArgs = {
  cb?: () => void
}

type OneTimeCustomPreferenceText = string | null
export type ICandidateAdsStore = {
  isListLoading: boolean
  isDetailsLoading: boolean
  pagination: Pagination
  candidateAds: ICandidateAdsListModel[]
  enquiries: IEnquiriesModel[]
  activeCandidateAdId: string
  activeCandidateExternalID: string
  candidateAdDetails: ICandidateAdsDetailsModel | null
  sortConfig: SortConfig
  search: string
  listTypeFilter: ListTypeFilterValues[]
  candidateAdIdFromQueryData?: string
  editCandidateAd: boolean
  createCandidateAdDeleteConfirmationModal: boolean
  editCandidateAdDeleteConfirmationModal: boolean
  isRepostConfirmationModalOpen: boolean
  isOpenCandidateAdDetailsDrawer: boolean
  isCandidateAdDetailsUpdated: boolean
  isOpenSalaryRangeCreateModal: boolean
  isOpenSalaryRangeEditModal: boolean
  isOpenCandidateAdCreateDialog: boolean
  isOpenExpiryConfirmationModal: boolean
  isCandidateAdDraftDeleteConfirmationModalOpen: boolean
  isUnPublishCandidateAdConfirmationModalOpen: boolean
  isFetchingLanguagePreferences: boolean
  selectedCandidateAdDraftId: string
  selectedCandidateId: number
  candidateInfoDisplayState: CandidateInfoDisplayState
  candidateInfoFromPartnerActions: CandidateInfoFromPartnerActions | null
  shouldShowPagination: boolean
  candidateAdMode?: CandidateAdMode
  candidateFiles: CandidateFileType[]
  selectedFiles: CandidateFileType[]
  languagesPreferencesList: LanguagePreference[]
  ownOnly: boolean
  toolbox: string[]
  isFetchingCustomPreferences: boolean
  customPreferences: Array<CustomPreference>
  isCustomPreferenceModalOpen: boolean
  customPreferenceAction: CustomPreferenceAction
  selectedCustomPreferenceId: SelectedCustomPreferenceId
  selectedCustomPreference: CustomPreference | null
  selectedCustomPreferenceToGenerateAIAd: SelectedCustomPreferenceToGenerateAIAd
  defaultCustomPreference: CustomPreference | null
  isCustomPreferenceDeleteConfirmationModalOpen: boolean
  oneTimeCustomPreferenceText: OneTimeCustomPreferenceText
  filterCandidateAds: (
    filters: Filters,
    onSuccess?: () => void
  ) => Promise<void>

  filterEnquiries: (filters: Filters, onSuccess?: () => void) => Promise<void>
  sortList: (sortKey: string) => Promise<void>
  createCandidateAd: (
    form: any,
    args: CreateCandidateAdAdditionalArgs
  ) => Promise<void>
  unPublishCandidateAd: (
    args: {
      selecetedKeysGetter: (
        selectedList: Array<any>,
        entireList: Array<any>
      ) => Array<any>
    },
    candidateAdId?: string
  ) => Promise<void>
  saveOrEditCandidateAdDraft: (
    form: any,
    args: CreateCandidateDraftAdAdditionalArgs
  ) => Promise<void>
  deleteCandidateAdDraft: (candidateDraftId: string) => Promise<void>
  partialUpdateCandidateAd: (
    form: any,
    args: UpdateCandidateAdAdditionalArgs
  ) => Promise<void>
  setFeaturedStatusForCandidateAd: (
    candidateAdId: string,
    isFeatured: boolean
  ) => Promise<boolean>
  expireCandidateAd: (id: string) => Promise<void>
  fetchCandidateAds: (onSuccess?: () => void) => Promise<void>
  setActiveCandidateAdId: (id: string) => void
  fetchCandidateAdDetails: (
    cb?: (candidate: ICandidateAdsDetailsModel | null) => void
  ) => Promise<void>
  setCandidateAdIdFromQueryData: (candidateAdId: string) => void
  resetcandidateAds: () => void
  resetCandidateAdIdFromQueryData: () => void
  fetchEnquiries: (onSuccess?: () => void) => Promise<void>
  resetEnquiries: () => void
  openEditCandidateAd: (
    openEditCandidateAdArgs?: OpenEditCandidateAdArgs
  ) => void
  closeEditCandidateAd: () => void
  openCreateCandidateAdDeleteConfirmationModal: () => void
  closeCreateCandidateAdDeleteConfirmationModal: () => void
  openEditCandidateAdDeleteConfirmationModal: () => void
  openRepostConfirmationModal: () => void
  closeEditCandidateAdDeleteConfirmationModal: () => void
  closeRepostConfirmationModal: () => void
  openCandidateAdDetailsDrawer: () => void
  closeCandidateAdDetailsDrawer: () => void
  openSalaryRangeCreateModal: () => void
  closeSalaryRangeCreateModal: () => void
  openSalaryRangeEditModal: () => void
  closeSalaryRangeEditModal: () => void
  openCandidateAdCreateDialog: () => void
  closeCandidateAdCreateDialog: () => void
  openCandidateAdDraftEditDialog: () => void
  closeCandidateAdDraftEditDialog: () => void
  openCandidateAdDraftDeleteConfirmationDialog: (
    selectedCandidateDraftId: string
  ) => void
  closeCandidateAdDraftDeleteConfirmationDialog: () => void
  setSelectedCandidateId: (candidateId: number) => void
  openUnPublishCanidateAdConfirmationDialog: () => void
  closeUnPublishCanidateAdConfirmationDialog: () => void
  resetSelectedCandidateId: () => void
  setCandidateInfoDisplayState: (
    candidateInfoDisplayState: CandidateInfoDisplayState
  ) => void
  setCandidateInfoFromPartnerActions: (
    candidateInfo: CandidateInfoFromPartnerActions
  ) => void
  resetCandidateInfoFromPartnerActitions: () => void
  setPaginationParams: (paginationParams: SetPaginationParams) => void
  setListTypeFilters: (values: ListTypeFilterValues[]) => void
  openExpiryConfirmationModal: () => void
  closeExpiryConfirmationModal: () => void
  generateCandidateAdvertContent: (data: {
    languagePreference: LanguagePreference
    selectedFileIds: number[]
    selectedCustomPreference:
      | SelectedCustomPreferenceToGenerateAIAd
      | OneTimeCustomPreferenceText
  }) => Promise<AIGeneratedCandidateAdResponse | undefined>
  getCandidateFiles: (candidate: {
    id: number | string
    isExternalID: boolean
  }) => Promise<GetCandidateFiles | null>
  // fix return type
  generateCandidateAdvertContentWithFileID: (
    fileId: string | number
  ) => Promise<AIGeneratedCandidateAdResponse | undefined>

  // fix return type
  generateCandidateAdvertContentWithExternalID: (
    fileExtenalID: string,
    candidateExternalID: string
  ) => Promise<AIGeneratedCandidateAdResponse | undefined>

  fetchLanguagePreference: (
    captchaToken: string
  ) => Promise<LanguagePreference[]>

  onSelectedFilesUpdate: (selectedFiles: CandidateFileType[]) => void

  fetchCandidateAdLink: (candidateAdId: string) => Promise<string>

  uploadCandidateResume: (
    file: File,
    captchaToken: string
  ) => Promise<CandidateFileType | null>

  fetchFilePreviewUrl: (fileId: number) => Promise<string>
  resetCandidateAdGenerationData: () => void
  resetCandidateAdListData: () => void
  setOwnOnly: (value: boolean) => void
  setToolboxFilter: (value: string[]) => void
  setIsFetchingCustomPreferences: (state: boolean) => void
  fetchCustomPreferences: () => Promise<void>
  createCustomPreference: (
    form: any,
    args: CreateCustomPreferenceAdditionalArgs
  ) => Promise<void>
  openCustomPreferenceModal: () => void
  closeCustomPreferenceModal: () => void
  setCustomPreferenceAction: (action: CustomPreferenceAction) => void
  setSelectedCustomPreferenceId: (
    customPreferenceId: SelectedCustomPreferenceId
  ) => void
  partialUpdateCustomPreference: (
    form: any,
    args: UpdateCustomPreferenceAdditionalArgs
  ) => Promise<void>
  deleteCustomPreference: (customPreferenceId: number) => Promise<void>
  openCustomPreferenceDeleteConfirmationModal: () => void
  closeCustomPreferenceDeleteConfirmationModal: () => void
  setSelectedCustomPreferenceToGenerateAIAd: (
    customPreference: SelectedCustomPreferenceToGenerateAIAd
  ) => void
  setOneTimeCustomPreferenceText: (text: OneTimeCustomPreferenceText) => void
}

class CandidateAdsStore implements ICandidateAdsStore {
  @observable isListLoading = false
  @observable isDetailsLoading = false
  @observable pagination: ICandidateAdsStore['pagination'] = {
    totalRecordCount: 0,
    currentPage: 0,
    totalPageCount: 0,
    pageSize: 0
  }
  @observable candidateAds: ICandidateAdsStore['candidateAds'] = []
  @observable enquiries: ICandidateAdsStore['enquiries'] = []
  @observable activeCandidateAdId = ''
  @observable candidateAdDetails: ICandidateAdsStore['candidateAdDetails'] =
    null
  @observable sortConfig: ICandidateAdsStore['sortConfig'] = {
    param: '',
    direction: false
  }
  @observable search = ''
  @observable listTypeFilter: ListTypeFilterValues[] = ['active', 'draft']
  @observable
  candidateAdIdFromQueryData: ICandidateAdsStore['candidateAdIdFromQueryData']
  @observable editCandidateAd = false
  @observable createCandidateAdDeleteConfirmationModal = false
  @observable editCandidateAdDeleteConfirmationModal = false
  @observable isRepostConfirmationModalOpen = false
  @observable isOpenCandidateAdDetailsDrawer = false
  @observable isCandidateAdDetailsUpdated = false
  @observable isOpenSalaryRangeCreateModal = false
  @observable isOpenSalaryRangeEditModal = false
  @observable isOpenCandidateAdCreateDialog = false
  @observable isCandidateAdDraftDeleteConfirmationModalOpen = false
  @observable isUnPublishCandidateAdConfirmationModalOpen = false
  @observable isFetchingLanguagePreferences = false
  @observable selectedCandidateAdDraftId = ''
  @observable selectedCandidateId = 0
  @observable candidateInfoDisplayState: CandidateInfoDisplayState = {
    showInfo: false,
    triggerSource: undefined
  }
  @observable
  candidateInfoFromPartnerActions: ICandidateAdsStore['candidateInfoFromPartnerActions'] =
    null
  @observable candidateAdMode: ICandidateAdsStore['candidateAdMode'] = undefined
  @observable isOpenExpiryConfirmationModal = false

  @observable candidateFiles: ICandidateAdsStore['candidateFiles'] = []
  @observable selectedFiles: ICandidateAdsStore['selectedFiles'] = []
  @observable
  languagesPreferencesList: ICandidateAdsStore['languagesPreferencesList'] = []
  @observable
  activeCandidateExternalID: ICandidateAdsStore['activeCandidateExternalID'] =
    ''

  @observable ownOnly = false
  @observable toolbox: string[] = []
  @observable isFetchingCustomPreferences = false
  @observable customPreferences: ICandidateAdsStore['customPreferences'] = []
  @observable isCustomPreferenceModalOpen = false
  @observable customPreferenceAction: CustomPreferenceAction = null
  @observable selectedCustomPreferenceId: SelectedCustomPreferenceId = null
  @observable isCustomPreferenceDeleteConfirmationModalOpen = false
  @observable selectedCustomPreferenceToGenerateAIAd: CustomPreference | null =
    null
  @observable oneTimeCustomPreferenceText: OneTimeCustomPreferenceText = null

  @action
  openExpiryConfirmationModal: ICandidateAdsStore['openExpiryConfirmationModal'] =
    () => {
      this.isOpenExpiryConfirmationModal = true
    }

  @action
  closeExpiryConfirmationModal: ICandidateAdsStore['closeExpiryConfirmationModal'] =
    () => {
      this.isOpenExpiryConfirmationModal = false
    }

  @action
  setCandidateInfoFromPartnerActions: ICandidateAdsStore['setCandidateInfoFromPartnerActions'] =
    (candidateInfo) => {
      if (candidateInfo) {
        const info: CandidateInfoFromPartnerActions = {
          id: undefined,
          firstName: '',
          lastName: '',
          email: '',
          externalID: ''
        }

        this.candidateInfoFromPartnerActions = info

        this.candidateInfoFromPartnerActions.id = candidateInfo.id
        this.candidateInfoFromPartnerActions.firstName = candidateInfo.firstName
        this.candidateInfoFromPartnerActions.lastName = candidateInfo.lastName
        this.candidateInfoFromPartnerActions.email = candidateInfo.email
        this.candidateInfoFromPartnerActions.externalID =
          candidateInfo.externalID
      }
    }

  @action
  resetCandidateInfoFromPartnerActitions: ICandidateAdsStore['resetCandidateInfoFromPartnerActitions'] =
    () => {
      this.candidateInfoFromPartnerActions = null
    }

  @action
  setCandidateInfoDisplayState: ICandidateAdsStore['setCandidateInfoDisplayState'] =
    (displayState) => {
      this.candidateInfoDisplayState.showInfo = displayState.showInfo
      this.candidateInfoDisplayState.triggerSource = displayState.triggerSource
    }

  @action setSelectedCandidateId: ICandidateAdsStore['setSelectedCandidateId'] =
    (candidateId) => {
      this.selectedCandidateId = candidateId
    }

  @action
  resetSelectedCandidateId: ICandidateAdsStore['resetSelectedCandidateId'] =
    () => {
      this.selectedCandidateId = 0
    }

  @action
  resetSelectedCandidateDraftId: ICandidateAdsStore['resetSelectedCandidateId'] =
    () => {
      this.selectedCandidateAdDraftId = ''
    }

  @action
  openCandidateAdCreateDialog: ICandidateAdsStore['openCandidateAdCreateDialog'] =
    () => {
      /**
       * @note reset location from standalone store before opening candidate ad create dialog
       */
      standAloneStore.resetLocationFieldIds()
      this.isOpenCandidateAdCreateDialog = true
    }

  @action
  closeCandidateAdCreateDialog: ICandidateAdsStore['closeCandidateAdCreateDialog'] =
    () => {
      this.isOpenCandidateAdCreateDialog = false
    }

  @action
  openCandidateAdDraftEditDialog: ICandidateAdsStore['openCandidateAdDraftEditDialog'] =
    () => {
      /**
       * @note make sure we've already set the active candidate ID
       */
      this.isOpenCandidateAdCreateDialog = true
      this.candidateAdMode = 'draft'
    }

  @action
  closeCandidateAdDraftEditDialog: ICandidateAdsStore['closeCandidateAdDraftEditDialog'] =
    () => {
      this.isOpenCandidateAdCreateDialog = false
      this.candidateAdMode = undefined
      this.candidateAdDetails = null
    }

  @action
  openSalaryRangeCreateModal: ICandidateAdsStore['openSalaryRangeCreateModal'] =
    () => {
      this.isOpenSalaryRangeCreateModal = true
    }

  @action
  closeSalaryRangeCreateModal: ICandidateAdsStore['closeSalaryRangeCreateModal'] =
    () => {
      this.isOpenSalaryRangeCreateModal = false
    }

  @action
  openSalaryRangeEditModal: ICandidateAdsStore['openSalaryRangeEditModal'] =
    () => {
      this.isOpenSalaryRangeEditModal = true
    }

  @action
  closeSalaryRangeEditModal: ICandidateAdsStore['closeSalaryRangeEditModal'] =
    () => {
      this.isOpenSalaryRangeEditModal = false
    }

  @action
  openCandidateAdDetailsDrawer: ICandidateAdsStore['openCandidateAdDetailsDrawer'] =
    () => {
      this.isOpenCandidateAdDetailsDrawer = true
    }

  @action
  closeCandidateAdDetailsDrawer: ICandidateAdsStore['closeCandidateAdDetailsDrawer'] =
    () => {
      this.isOpenCandidateAdDetailsDrawer = false
    }

  @action
  openEditCandidateAd: ICandidateAdsStore['openEditCandidateAd'] = (
    editArgs
  ) => {
    this.editCandidateAd = true
    this.candidateAdMode = editArgs?.candidateAdMode
  }

  @action
  closeEditCandidateAd: ICandidateAdsStore['closeEditCandidateAd'] = () => {
    this.editCandidateAd = false
    this.candidateAdMode = undefined
  }

  @action
  openCreateCandidateAdDeleteConfirmationModal: ICandidateAdsStore['openCreateCandidateAdDeleteConfirmationModal'] =
    () => {
      this.createCandidateAdDeleteConfirmationModal = true
    }

  @action
  closeCreateCandidateAdDeleteConfirmationModal: ICandidateAdsStore['closeCreateCandidateAdDeleteConfirmationModal'] =
    () => {
      this.createCandidateAdDeleteConfirmationModal = false
    }

  @action
  openEditCandidateAdDeleteConfirmationModal: ICandidateAdsStore['openEditCandidateAdDeleteConfirmationModal'] =
    () => {
      this.editCandidateAdDeleteConfirmationModal = true
    }

  @action
  openRepostConfirmationModal: ICandidateAdsStore['openRepostConfirmationModal'] =
    () => {
      this.isRepostConfirmationModalOpen = true
    }

  @action
  closeEditCandidateAdDeleteConfirmationModal: ICandidateAdsStore['closeEditCandidateAdDeleteConfirmationModal'] =
    () => {
      this.editCandidateAdDeleteConfirmationModal = false
    }

  @action
  closeRepostConfirmationModal: ICandidateAdsStore['closeRepostConfirmationModal'] =
    () => {
      this.isRepostConfirmationModalOpen = false
    }

  @action
  openUnPublishCanidateAdConfirmationDialog = () => {
    this.isUnPublishCandidateAdConfirmationModalOpen = true
  }

  @action
  closeUnPublishCanidateAdConfirmationDialog = () => {
    this.isUnPublishCandidateAdConfirmationModalOpen = false
  }

  @action
  openCandidateAdDraftDeleteConfirmationDialog: ICandidateAdsStore['openCandidateAdDraftDeleteConfirmationDialog'] =
    (selectedCandidateDraftID: string) => {
      this.isCandidateAdDraftDeleteConfirmationModalOpen = true
      this.selectedCandidateAdDraftId = selectedCandidateDraftID
    }

  @action
  closeCandidateAdDraftDeleteConfirmationDialog: ICandidateAdsStore['closeCandidateAdDraftDeleteConfirmationDialog'] =
    () => {
      this.isCandidateAdDraftDeleteConfirmationModalOpen = false
      this.selectedCandidateAdDraftId = ''
    }

  @action
  setCandidateAdIdFromQueryData: ICandidateAdsStore['setCandidateAdIdFromQueryData'] =
    (candidateAdId: string) => {
      this.candidateAdIdFromQueryData = candidateAdId
    }

  @action
  private setIsListLoading = (state: boolean): void => {
    this.isListLoading = state
  }

  @action
  private setIsDetailsLoading = (state: boolean): void => {
    this.isDetailsLoading = state
  }

  @action
  createCandidateAd: ICandidateAdsStore['createCandidateAd'] = async (
    form,
    {
      candidateId,
      selecetedKeysGetter,
      canAddCandidate,
      candidateAdMode,
      candidateDraftId,
      cb
    }
  ) => {
    const data = { ...form.data }
    console.log('createCandidateAd', data)

    const isDraftPublish = candidateAdMode === 'draft' && candidateDraftId

    const hasCandidateId =
      this.candidateInfoFromPartnerActions?.id ||
      this.selectedCandidateId ||
      candidateId

    const dataTobeSentToServer: CandidateAdCreateSchema = {
      title: data.title,
      description: wrapIframesWithDiv(data.description),
      ...(hasCandidateId && {
        candidate: candidateId
      }),
      summary: data.summary,
      ...(!hasCandidateId && {
        candidate_data: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email
        }
      }),
      consultant: Number(data.consultant),
      locations: Array.isArray(data.locations)
        ? data.locations.map((location: any) => {
            return location?.id
          })
        : [],
      worktypes: selecetedKeysGetter(
        data.worktypes.map((worktype: string | Record<string, any>) => {
          if (typeof worktype === 'string') {
            return worktype
          }

          return worktype.name
        }),
        standAloneStore.getWorkTypesList
      ),
      skills: selecetedKeysGetter(
        data.skills.map((skill: string | Record<string, any>) => {
          if (typeof skill === 'string') {
            return skill
          }

          return skill.name
        }),
        standAloneStore.getCandidateSkillsList
      ),
      salary_rate_period:
        data.salary_rate_period === ''
          ? null
          : data.salary_rate_period.toLowerCase(),
      salary_currency: data.salary_currency,
      // @ts-ignore incorrect type from API
      salary_rate_max:
        data.salary_rate_period === '' ? null : Number(data.salary_rate_max),
      // @ts-ignore incorrect type from API
      salary_rate_min:
        data.salary_rate_period === '' ? null : Number(data.salary_rate_min),
      expired_at: moment(data.expired_at).format(),
      requested_verification: data.requested_verification
    }

    try {
      const response = isDraftPublish
        ? await API.postData(
            ApiRoutes.dashboard.candidateAd.publishDraft(candidateDraftId),
            dataTobeSentToServer
          )
        : await API.postData(
            ApiRoutes.dashboard.candidateAd.create,
            dataTobeSentToServer
          )

      if (response.status === 201 || response.status === 200) {
        // repost action is happening on candidate ad page
        // create action in happening on candidate page
        if (candidateAdMode === 'repost') {
          toast.success(alertMessages.candidateAd.repost.success)

          standAloneStore.resetLocationFieldIds()
          standAloneStore.resetLocationRecord()

          this.closeEditCandidateAd()
          this.closeCandidateAdDetailsDrawer()
          this.fetchCandidateAds()

          this.isCandidateAdDetailsUpdated = true
        } else if (candidateAdMode === 'draft') {
          toast.success(alertMessages.candidateAd.publishDraft.success)

          standAloneStore.resetLocationFieldIds()
          standAloneStore.resetLocationRecord()

          this.closeEditCandidateAd()
          this.closeCandidateAdCreateDialog()
          this.closeCandidateAdDetailsDrawer()
          this.fetchCandidateAds()

          this.isCandidateAdDetailsUpdated = true
        } else {
          toast.success(alertMessages.candidateAd.create.success)

          standAloneStore.resetLocationFieldIds()
          standAloneStore.resetLocationRecord()

          this.closeCandidateAdCreateDialog()

          if (canAddCandidate) {
            this.fetchCandidateAds()

            this.setCandidateInfoDisplayState({
              showInfo: false,
              triggerSource: undefined
            })

            this.resetSelectedCandidateId()
          } else {
            candidatesMainStore.fetchCandidates()
          }
        }
        typeof cb === 'function' && cb()
      } else {
        toast.error(alertMessages.genericErrorMessage)
      }
    } catch (error) {
      // @ts-ignore add types for error
      mapFormServerErrors(error?.data, form.fields)
      const errorMessage = generateErrorMessageFromFieldErrors(
        // @ts-ignore add types for error
        error?.data,
        form.fields
      )

      Logger.error(error as any)
      const candidateDetailsErrorMessages = this.mapServerErrorForCandidateData(
        // @ts-ignore add types for error
        error?.data,
        form.fields
      )
      if (candidateDetailsErrorMessages.length > 0) {
        toast.error(candidateDetailsErrorMessages[0], { autoClose: 4000 })
        return
      }
      if (Array.isArray(errorMessage) && errorMessage.length > 0) {
        toast.error(errorMessage[0], { autoClose: 4000 })
      } else {
        toast.error(alertMessages.genericErrorMessage, { autoClose: 4000 })
      }
    }
  }

  @action
  unPublishCandidateAd = async (
    args: {
      selecetedKeysGetter: (
        selectedList: Array<any>,
        entireList: Array<any>
      ) => Array<any>
    },
    candidateAdId?: string
  ) => {
    const id = candidateAdId || this.activeCandidateAdId
    try {
      if (!id || !this.candidateAdDetails) {
        throw new Error('ID is required')
      }

      const hasCandidateId =
        this.candidateInfoFromPartnerActions?.id ||
        this.selectedCandidateId ||
        this.candidateAdDetails.candidate.id
      const candidateId =
        this.candidateInfoFromPartnerActions?.id ||
        this.selectedCandidateId ||
        this.candidateAdDetails.candidate.id

      const dataTobeSentToServer: CandidateAdCreateSchema = {
        title: this.candidateAdDetails.title,
        description: wrapIframesWithDiv(this.candidateAdDetails.description),
        ...(hasCandidateId && {
          candidate: candidateId
        }),
        summary: this.candidateAdDetails.summary,
        ...(!hasCandidateId && {
          candidate_data: {
            first_name: this.candidateAdDetails.candidate.first_name,
            last_name: this.candidateAdDetails.candidate.last_name,
            email: this.candidateAdDetails.candidate.email
          }
        }),
        consultant: Number(this.candidateAdDetails.consultant.id),
        locations: Array.isArray(this.candidateAdDetails.locations)
          ? this.candidateAdDetails.locations
              .map((location) => location.location)
              .filter((id) => typeof id === 'number')
          : [],
        worktypes: this.candidateAdDetails.worktypes
          .map((worktype) => worktype.id)
          .filter((id) => typeof id === 'number'),

        skills: this.candidateAdDetails.skills
          .map((skills) => skills.id)
          .filter((id) => typeof id === 'number'),

        ...(this.candidateAdDetails.salary && {
          salary_rate_period: this.candidateAdDetails.salary.period,
          salary_currency:
            this.candidateAdDetails.salary.rateMin?.currency ||
            this.candidateAdDetails.salary.rateMax?.currency,

          salary_rate_max: this.candidateAdDetails.salary.rateMax?.amount,

          salary_rate_min: this.candidateAdDetails.salary.rateMin?.amount
        }),

        // @ts-ignore incorrect type from API
        expired_at: moment(this.candidateAdDetails.expiredAt).format()
      }

      const response = await API.postData(
        ApiRoutes.dashboard.candidateAd.unPublishCandidateAd(id),
        dataTobeSentToServer
      )
      if (response.status === 200) {
        toast.success(alertMessages.candidateAd.unPublishCandidateAd.success)
        runInAction(() => {
          this.candidateAdDetails = null
        })
        this.closeCandidateAdDetailsDrawer()
        this.fetchCandidateAds()
      }
    } catch (error) {
      Logger.error(error as any)
      toast.error(alertMessages.genericErrorMessage)
    }
  }

  @action
  saveOrEditCandidateAdDraft = async (
    form: any,
    args: CreateCandidateDraftAdAdditionalArgs
  ) => {
    const data = { ...form.data }
    const { selecetedKeysGetter } = args
    console.log('saveOrEditCandidateAdDraft', data)

    const hasCandidateId =
      this.candidateInfoFromPartnerActions?.id ||
      this.selectedCandidateId ||
      args.candidateId

    const dataTobeSentToServer: CandidateAdCreateSchema = {
      title: data.title,
      description: data.description ? wrapIframesWithDiv(data.description) : '',
      ...(hasCandidateId && {
        candidate: args.candidateId
      }),
      ...(!hasCandidateId &&
        (data.first_name || data.last_name || data.email) && {
          candidate_data: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email
          }
        }),
      summary: data.summary,
      consultant: data.consultant && Number(data.consultant),
      locations: Array.isArray(data.locations)
        ? data.locations.map((location: any) => {
            return location?.id
          })
        : [],
      worktypes: Array.isArray(data.worktypes)
        ? selecetedKeysGetter(
            data.worktypes.map((worktype: string | Record<string, any>) => {
              if (typeof worktype === 'string') {
                return worktype
              }

              return worktype.name
            }),
            standAloneStore.getWorkTypesList
          )
        : [],
      skills: Array.isArray(data.skills)
        ? selecetedKeysGetter(
            data.skills.map((skill: string | Record<string, any>) => {
              if (typeof skill === 'string') {
                return skill
              }

              return skill.name
            }),
            standAloneStore.getCandidateSkillsList
          )
        : [],
      salary_rate_period: !data.salary_rate_period
        ? null
        : data.salary_rate_period.toLowerCase(),
      salary_currency: data.salary_currency,
      // @ts-ignore incorrect type from API
      salary_rate_max: !data.salary_rate_period
        ? null
        : Number(data.salary_rate_max),
      // @ts-ignore incorrect type from API
      salary_rate_min: !data.salary_rate_period
        ? null
        : Number(data.salary_rate_min),
      ...(data.expired_at && {
        expired_at: moment(data.expired_at).format(),
        requested_verification: data.requested_verification
      })
    }
    try {
      const response = args.candidateAdId
        ? await API.patchData(
            ApiRoutes.dashboard.candidateAd.editDraft(args.candidateAdId),
            dataTobeSentToServer
          )
        : await API.postData(
            ApiRoutes.dashboard.candidateAd.createDraft,
            dataTobeSentToServer
          )
      if (response.status === 201 || response.status === 200) {
        args.candidateAdId
          ? toast.success(alertMessages.candidateAd.editDraft.success)
          : toast.success(alertMessages.candidateAd.createDraft.success)
        standAloneStore.resetLocationFieldIds()
        standAloneStore.resetLocationRecord()

        this.closeCandidateAdCreateDialog()
        this.resetSelectedCandidateId()
        this.candidateAdDetails = null

        this.fetchCandidateAds()
        typeof args.cb === 'function' && args.cb()
      }
    } catch (error) {
      // @ts-ignore add types for error
      mapFormServerErrors(error?.data, form.fields)
      const errorMessage = generateErrorMessageFromFieldErrors(
        // @ts-ignore add types for error
        error?.data,
        form.fields
      )

      Logger.error(error as any)
      const candidateDetailsErrorMessages = this.mapServerErrorForCandidateData(
        // @ts-ignore add types for error
        error?.data,
        form.fields
      )
      if (candidateDetailsErrorMessages.length > 0) {
        toast.error(candidateDetailsErrorMessages[0], { autoClose: 4000 })
        return
      }
      if (Array.isArray(errorMessage) && errorMessage.length > 0) {
        toast.error(errorMessage[0], { autoClose: 4000 })
      } else {
        toast.error(alertMessages.genericErrorMessage, { autoClose: 4000 })
      }
    }
  }

  mapServerErrorForCandidateData = (data: any, fields: any) => {
    const { candidate_data } = data
    const errorMessages = []
    if (candidate_data) {
      const { first_name, last_name, email } = candidate_data
      if (Array.isArray(first_name) && first_name.length > 0) {
        errorMessages.push(`Error: ${first_name[0]}`)
        fields?.candidateFirstName?.setServerErrors(first_name)
      }
      if (Array.isArray(last_name) && last_name.length > 0) {
        errorMessages.push(`Error: ${last_name[0]}`)
        fields?.candidateLastName?.setServerErrors(last_name)
      }
      if (Array.isArray(email) && email.length > 0) {
        errorMessages.push(`Error: ${email[0]}`)
        fields?.candidateEmail?.setServerErrors(email)
      }
    }
    return errorMessages
  }

  @action
  deleteCandidateAdDraft = async (candidateDraftId: string) => {
    try {
      const response = await API.deleteData(
        ApiRoutes.dashboard.candidateAd.draftDetails(candidateDraftId)
      )
      if (response.status === 204) {
        toast.success(alertMessages.candidateAd.deleteDraft.success)
        standAloneStore.resetLocationFieldIds()
        standAloneStore.resetLocationRecord()
        runInAction(() => {
          this.candidateAdDetails = null
          this.activeCandidateAdId = ''
        })
        this.resetSelectedCandidateId()
        this.resetSelectedCandidateDraftId()
        this.fetchCandidateAds()
        this.closeCandidateAdDraftDeleteConfirmationDialog()
      }
    } catch (error) {
      Logger.error(error as any)
      toast.error(alertMessages.genericErrorMessage)
    }
  }

  @computed
  private get getParams(): ListParams {
    const params: ListParams = {
      list_type: this.listTypeFilter.join(',')
    }

    if (this.sortConfig.param) {
      params.ordering = this.sortConfig.direction
        ? this.sortConfig.param
        : `-${this.sortConfig.param}`
    }

    if (this.pagination.currentPage) {
      params.page = this.pagination.currentPage
    }

    if (this.search) {
      params.search = this.search
    }

    if (this.candidateAdIdFromQueryData) {
      params.candidate_id = this.candidateAdIdFromQueryData
    }

    if (this.ownOnly) {
      params.own_only = true
    }

    if (this.toolbox.length > 0) {
      params.toolbox = this.toolbox.join(',')
    }

    return params
  }

  @action
  fetchCandidateAds: ICandidateAdsStore['fetchCandidateAds'] = async (
    onSuccess
  ) => {
    try {
      this.setIsListLoading(true)

      const response: APIResponse<CandidateAdsListResponse> = await API.getData(
        ApiRoutes.dashboard.candidateAd.list,
        this.getParams
      )

      if (!response.data) {
        throw new Error("FetchCandidateAds API Response:: doesn't contain data")
      }

      const candidatesResponseArray = response.data.results

      runInAction(() => {
        this.candidateAds = candidatesResponseArray.map(
          (item) => new CandidateAdsListModel(item)
        )
        /**
         * @TODO default 0 value setting in here is not safe
         */
        this.setPaginationParams({
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        })

        onSuccess && onSuccess()
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setIsListLoading(false)
    }
  }

  @action
  fetchCandidateAdDetails: ICandidateAdsStore['fetchCandidateAdDetails'] =
    async (cb) => {
      try {
        const isDraft =
          this.candidateAds.filter(
            (candidate) => candidate.id === this.activeCandidateAdId
          )[0]?.status === 'draft'
        this.setIsDetailsLoading(true)

        const response: APIResponse<CandidateAdsDetailsSchema> =
          await API.getData(
            isDraft
              ? ApiRoutes.dashboard.candidateAd.draftDetails(
                  this.activeCandidateAdId
                )
              : ApiRoutes.dashboard.candidateAd.details(
                  this.activeCandidateAdId
                )
          )

        const { data } = response

        if (!data) {
          throw new Error(
            "FetchCandidateAdDetails API Response:: doesn't contain data"
          )
        }

        runInAction(() => {
          this.candidateAdDetails = new CandidateAdsDetailsModel(data)

          this.isCandidateAdDetailsUpdated = false

          standAloneStore.locationFieldIds =
            this.candidateAdDetails.locations.map(
              (locationModel) => locationModel.fieldId
            )

          this.candidateAdDetails.locations.forEach((locationModel, index) => {
            standAloneStore.updateLocationRecord({
              fieldId: standAloneStore.locationFieldIds[index],
              parentLocationId: String(locationModel.location),
              hasSubLocation: false
            })
          })
          typeof cb === 'function' && cb(this.candidateAdDetails)
        })
      } catch (error) {
        Logger.error(error as any)
      } finally {
        this.setIsDetailsLoading(false)
      }
    }

  @action
  partialUpdateCandidateAd: ICandidateAdsStore['partialUpdateCandidateAd'] =
    async (form, { selecetedKeysGetter }) => {
      const data = { ...form.data }
      console.log('partialUpdateCandidateAd', data)

      const dataTobeSentToServer: Partial<CandidateAdCreateSchema> = {
        title: data.title,
        locations: Array.isArray(data.locations)
          ? data.locations.map((location: any) => {
              return location?.id
            })
          : [],
        consultant: Number(data.consultant),
        salary_rate_period:
          data.salary_rate_period === ''
            ? null
            : data.salary_rate_period.toLowerCase(),
        salary_currency: data.salary_currency,
        // @ts-ignore incorrect type from API
        salary_rate_max:
          data.salary_rate_period === '' ? null : Number(data.salary_rate_max),
        // @ts-ignore incorrect type from API
        salary_rate_min:
          data.salary_rate_period === '' ? null : Number(data.salary_rate_min),
        description: wrapIframesWithDiv(data.description),
        summary: data.summary,
        expired_at: moment(data.expired_at).format(),
        requested_verification: data.requested_verification,
        worktypes: selecetedKeysGetter(
          data.worktypes.map((worktype: string | Record<string, any>) => {
            if (typeof worktype === 'string') {
              return worktype
            }

            return worktype.name
          }),
          standAloneStore.getWorkTypesList
        ),
        skills: selecetedKeysGetter(
          data.skills.map((skill: string | Record<string, any>) => {
            if (typeof skill === 'string') {
              return skill
            }

            return skill.name
          }),
          standAloneStore.getCandidateSkillsList
        )
      }

      try {
        const response = await API.patchData(
          ApiRoutes.dashboard.candidateAd.partialUpdate(
            this.activeCandidateAdId
          ),
          dataTobeSentToServer
        )

        if (response.status === 200) {
          toast.success(alertMessages.candidateAd.update.success)

          standAloneStore.resetLocationFieldIds()
          standAloneStore.resetLocationRecord()

          this.closeEditCandidateAd()
          this.closeCandidateAdDetailsDrawer()
          this.fetchCandidateAds()

          this.isCandidateAdDetailsUpdated = true
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      } catch (error) {
        Logger.error(error as any)

        toast.error(alertMessages.genericErrorMessage)
      }
    }

  setFeaturedStatusForCandidateAd: ICandidateAdsStore['setFeaturedStatusForCandidateAd'] =
    async (candidateAdID, isFeatured) => {
      try {
        const response = await API.patchData(
          ApiRoutes.dashboard.candidateAd.partialUpdate(candidateAdID),
          {
            is_agency_featured: isFeatured
          }
        )
        if (response.status === 200) {
          toast.success(alertMessages.candidateAd.update.success)
          this.fetchCandidateAds()
          return true
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      } catch (error) {
        Logger.error(error as any)
        toast.error(alertMessages.genericErrorMessage)
      }
      return false
    }

  @action
  expireCandidateAd: ICandidateAdsStore['expireCandidateAd'] = async (id) => {
    try {
      const response = await API.deleteData(
        ApiRoutes.dashboard.candidateAd.expire(id)
      )

      if (response.status === 200) {
        toast.success(alertMessages.candidateAd.expire.success)

        this.fetchCandidateAds()
      } else {
        toast.error(alertMessages.genericErrorMessage)
      }
    } catch (error) {
      Logger.error(error as any)

      toast.error(alertMessages.genericErrorMessage)
    }
  }

  @action
  sortList: ICandidateAdsStore['sortList'] = async (sortKey: string) => {
    this.pagination.currentPage = 1

    if (this.sortConfig.param === sortKey) {
      /**
       * change the direction
       */
      this.sortConfig = {
        param: sortKey,
        direction: !this.sortConfig.direction
      }
    } else {
      this.sortConfig = {
        param: sortKey,
        direction: false
      }
    }
    this.fetchCandidateAds()
  }

  @action
  filterCandidateAds: ICandidateAdsStore['filterCandidateAds'] = async (
    filters,
    onSuccess
  ) => {
    if (typeof filters.search !== 'undefined') {
      this.search = filters.search
    }

    if (filters.list_type) {
      this.listTypeFilter = filters.list_type.split(
        ','
      ) as ListTypeFilterValues[]
    }

    if (
      typeof filters.page !== 'undefined' &&
      this.pagination.currentPage !== filters.page
    ) {
      this.pagination.currentPage = filters.page
    }

    this.fetchCandidateAds(onSuccess)
  }

  @action
  filterEnquiries: ICandidateAdsStore['filterEnquiries'] = async (
    filters,
    onSuccess
  ) => {
    if (typeof filters.search !== 'undefined') {
      this.search = filters.search
    }

    if (
      typeof filters.page !== 'undefined' &&
      this.pagination.currentPage !== filters.page
    ) {
      this.pagination.currentPage = filters.page
    }

    this.fetchEnquiries(onSuccess)
  }

  @action
  setActiveCandidateAdId: ICandidateAdsStore['setActiveCandidateAdId'] = (
    id
  ) => {
    this.activeCandidateAdId = id
  }

  @action
  resetcandidateAds: ICandidateAdsStore['resetcandidateAds'] = () => {
    this.candidateAds = []
  }

  @action
  resetCandidateAdIdFromQueryData: ICandidateAdsStore['resetCandidateAdIdFromQueryData'] =
    () => {
      this.candidateAdIdFromQueryData = undefined
    }

  @action
  fetchEnquiries: ICandidateAdsStore['fetchEnquiries'] = async (onSuccess) => {
    try {
      this.setIsListLoading(true)

      const response: APIResponse<
        RefariDTOTypes['/dashboard/hiring-manager/enquiries/']['get']['responses']['200']['content']['application/json']
      > = await API.getData(ApiRoutes.dashboard.enquiries.list, this.getParams)

      if (!response.data) {
        throw new Error("FetchCandidateAds API Response:: doesn't contain data")
      }

      const enqiriesResponseArray = response.data.results

      runInAction(() => {
        this.enquiries = enqiriesResponseArray.map(
          (item) => new EnquiriesModel(item)
        )
        /**
         * @TODO default 0 value setting in here is not safe
         */
        this.setPaginationParams({
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        })

        onSuccess && onSuccess()
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setIsListLoading(false)
    }
  }

  @action
  resetEnquiries: ICandidateAdsStore['resetEnquiries'] = () => {
    this.enquiries = []
  }

  @action
  setPaginationParams: ICandidateAdsStore['setPaginationParams'] = ({
    totalRecordCount,
    currentPage,
    totalPageCount,
    pageSize
  }) => {
    if (totalRecordCount) {
      this.pagination.totalRecordCount = totalRecordCount
    }

    if (currentPage) {
      this.pagination.currentPage = currentPage
    }

    if (totalPageCount) {
      this.pagination.totalPageCount = totalPageCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
  }

  @action
  generateCandidateAdvertContent: ICandidateAdsStore['generateCandidateAdvertContent'] =
    async (requestData) => {
      try {
        const payload: NonNullable<
          RefariDTOTypes['/dashboard/candidate-ads/generate-ai/']['post']['requestBody']
        >['content']['application/json'] = {
          language_preference: requestData.languagePreference.id,
          file_ids: requestData.selectedFileIds,
          custom_preference:
            typeof requestData.selectedCustomPreference === 'string'
              ? requestData.selectedCustomPreference
              : requestData.selectedCustomPreference?.id.toString()
        }

        const response: APIResponse<AIGeneratedCandidateAdResponse> =
          await API.postData(
            ApiRoutes.dashboard.candidateAd.generateCandidateAdContent,
            payload
          )

        const { data } = response
        if (data) {
          return data
        }
        throw response
      } catch (error) {
        Logger.error(error as any)
        // @ts-ignore type error objects
        if (error?.response?.status === 503) {
          toast.error(
            // @ts-ignore type error objects
            error?.response?.data?.detail ||
              'Error with connection to AI service'
          )
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      }
    }

  @action
  uploadCandidateResume = async (file: File, captchaToken: string) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('recaptcha_response', captchaToken)
      const response = await API.postData(
        ApiRoutes.dashboard.candidateAd.uploadCandidateResume,
        formData
        // {
        //   captcha: captchaToken
        // }
      )
      if (response.status === 201) {
        /**
         * @note append the uploaded file at the start of list
         */
        const fileData = response.data as UploadCandidateResumeResponse
        const uploadedCandidateFileData: CandidateFileType = {
          content: fileData.mime_type,
          file: fileData.file,
          preview: fileData.preview,
          name: file.name,
          id: fileData.id,
          updated_at: new Date().toUTCString(),
          isUploaded: true
        }
        runInAction(() => {
          this.candidateFiles = [
            uploadedCandidateFileData,
            ...this.candidateFiles
          ]
        })
        return uploadedCandidateFileData
        // return response.data as UploadCandidateResumeResponse
      }
      return null
    } catch (error) {
      Logger.error(error as any)
      toast.error('something went wrong while uploading')
      return null
    }
  }

  fetchFilePreviewUrl = async (filedId: number) => {
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.candidateAd.getFilePreviewUrl,
        { id: filedId }
      )
      /**
       * @note type this properly
       */
      return response.data.preview
    } catch (error) {
      Logger.error(error as any)
      toast.error('something went wrong, Try Again later')
      return ''
    }
  }

  fetchCandidateAdLink = async (candidateAdId: string) => {
    try {
      const res: APIResponse<
        RefariDTOTypes['/public-posts/posts/talent_link/']['post']['responses']['201']['content']['application/json']
      > = await API.postData(
        ApiRoutes.dashboard.candidateAd.getCandidateAdLink,
        {
          candidate_ad: candidateAdId
        }
      )

      return res.data?.ssr_link || ''
    } catch (e) {
      Logger.error(e as never)
      return ''
    }
  }

  @action
  generateCandidateAdvertContentWithExternalID: ICandidateAdsStore['generateCandidateAdvertContentWithExternalID'] =
    async (fileExternalID, candidateExternalID) => {
      try {
        const response: APIResponse<AIGeneratedCandidateAdResponse> =
          await API.postData(
            ApiRoutes.dashboard.candidateAd
              .generateCandidateAdContentWithExternalFileID,
            {
              file_external_id: fileExternalID,
              candidate_external_id: candidateExternalID
            }
          )

        const { data } = response
        if (data) {
          return data
        }
        throw response
      } catch (error) {
        Logger.error(error as any)
        // @ts-ignore type error objects
        if (error?.response?.status === 503) {
          toast.error(
            // @ts-ignore type error objects
            error?.response?.data?.detail ||
              'Something went wrong, Try Again Later'
          )
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      }
    }

  @action
  generateCandidateAdvertContentWithFileID: ICandidateAdsStore['generateCandidateAdvertContentWithFileID'] =
    async (fileID) => {
      try {
        const response: APIResponse<AIGeneratedCandidateAdResponse> =
          await API.postData(
            ApiRoutes.dashboard.candidateAd
              .generateCandidateAdContentWithFileId,
            { file_id: fileID }
          )

        const { data } = response
        if (data) {
          return data
        }
        throw response
      } catch (error) {
        Logger.error(error as any)
        // @ts-ignore type error objects
        if (error?.response?.status === 503) {
          toast.error(
            // @ts-ignore type error objects
            error?.response?.data?.detail ||
              'Something went wrong, Try Again Later'
          )
        } else {
          toast.error(alertMessages.genericErrorMessage)
        }
      }
    }

  @action
  fetchLanguagePreference: ICandidateAdsStore['fetchLanguagePreference'] =
    async (captchaToken) => {
      try {
        this.isFetchingLanguagePreferences = true
        const response = await API.getData(
          ApiRoutes.dashboard.candidateAd.getLangugagePreferences,
          '',
          {
            captcha: captchaToken
          }
        )
        if (response.status === 200) {
          runInAction(() => {
            this.isFetchingLanguagePreferences = false
            this.languagesPreferencesList = response.data
          })
          return response.data
        }
      } catch (error) {
        Logger.error(error as any)
      }
      return []
    }

  @action
  getCandidateFiles: ICandidateAdsStore['getCandidateFiles'] = async (
    candidate
  ) => {
    try {
      const payload = candidate.isExternalID
        ? { candidate_external_id: candidate.id }
        : { candidate_id: candidate.id }
      const response: APIResponse<GetCandidateFiles> = await API.postData(
        ApiRoutes.dashboard.candidateAd.getCandidateFiles,
        payload
      )
      const { data } = response
      runInAction(() => {
        this.candidateFiles = data?.files_data || []
        this.activeCandidateExternalID = data?.candidate_external_id || ''
      })

      return data
    } catch (error) {
      Logger.error(error as any)
      return null
    }
  }

  @action
  onSelectedFilesUpdate: ICandidateAdsStore['onSelectedFilesUpdate'] = (
    selectedFiles
  ) => {
    console.groupEnd()
    this.selectedFiles = selectedFiles
  }

  @action
  setListTypeFilters = (values: ListTypeFilterValues[]) => {
    this.listTypeFilter = values
  }

  @computed
  get shouldShowPagination() {
    return this.pagination.totalRecordCount > this.pagination.pageSize
  }

  @action
  resetCandidateAdGenerationData() {
    this.activeCandidateExternalID = ''
    this.selectedFiles = []
    this.candidateFiles = []
    this.selectedCustomPreferenceToGenerateAIAd = null
    this.oneTimeCustomPreferenceText = null
  }

  @action
  resetCandidateAdListData() {
    this.search = ''
    this.listTypeFilter = ['active', 'draft']
    this.ownOnly = false
    this.toolbox = []
  }

  @action
  setOwnOnly = (value: boolean) => {
    this.ownOnly = value
  }

  @action
  setToolboxFilter = (value: string[]) => {
    console.log('toolbox filters', value)
    this.toolbox = value
  }

  @action
  setIsFetchingCustomPreferences: ICandidateAdsStore['setIsFetchingCustomPreferences'] =
    (state: boolean) => {
      this.isFetchingCustomPreferences = state
    }

  @action
  openCustomPreferenceModal: ICandidateAdsStore['openCustomPreferenceModal'] =
    () => {
      this.isCustomPreferenceModalOpen = true
    }

  @action
  closeCustomPreferenceModal: ICandidateAdsStore['closeCustomPreferenceModal'] =
    () => {
      this.isCustomPreferenceModalOpen = false
      this.customPreferenceAction = null
    }

  @action
  setCustomPreferenceAction: ICandidateAdsStore['setCustomPreferenceAction'] = (
    action: CustomPreferenceAction
  ) => {
    this.customPreferenceAction = action
  }

  @action
  fetchCustomPreferences: ICandidateAdsStore['fetchCustomPreferences'] =
    async () => {
      try {
        this.setIsFetchingCustomPreferences(true)

        const response: APIResponse<CustomPreferenceListResponse> =
          await API.getData(
            ApiRoutes.dashboard.candidateAd.customPreference.list
          )

        if (!response.data) {
          throw new Error('fetchCustomPreferences: no data found')
        }

        runInAction(() => {
          this.customPreferences = response.data as Array<CustomPreference>
        })
      } catch (error) {
        Logger.error(error as any)
      } finally {
        this.setIsFetchingCustomPreferences(false)
      }
    }

  @action
  createCustomPreference: ICandidateAdsStore['createCustomPreference'] = async (
    form,
    { cb }
  ) => {
    try {
      const data = { ...form.data }

      const payload = {
        name: data.name,
        prompt: data.prompt,
        is_default: data.is_default
      }

      const response = await API.postData(
        ApiRoutes.dashboard.candidateAd.customPreference.create,
        payload
      )

      if (response.status === 201) {
        toast.success(alertMessages.candidateAd.AICustomPreference.success)

        this.fetchCustomPreferences()

        cb?.()
      }
    } catch (error) {
      Logger.error(error as any)

      // @ts-ignore add types for error
      mapFormServerErrors(error?.data, form.fields)

      const errorMessage = generateErrorMessageFromFieldErrors(
        // @ts-ignore add types for error
        error?.data,
        form.fields
      )

      if (Array.isArray(errorMessage) && errorMessage.length > 0) {
        toast.error(errorMessage[0], { autoClose: 4000 })
      } else {
        toast.error(alertMessages.genericErrorMessage, { autoClose: 4000 })
      }
    }
  }

  @action
  setSelectedCustomPreferenceId: ICandidateAdsStore['setSelectedCustomPreferenceId'] =
    (customPreferenceId: number | null) => {
      this.selectedCustomPreferenceId = customPreferenceId
    }

  @computed
  get selectedCustomPreference() {
    return (
      this.customPreferences.find(
        (customPreference) =>
          customPreference.id === this.selectedCustomPreferenceId
      ) || null
    )
  }

  @computed
  get defaultCustomPreference() {
    return (
      this.customPreferences.find(
        (customPreference) => customPreference.is_default
      ) || null
    )
  }

  @action
  partialUpdateCustomPreference: ICandidateAdsStore['partialUpdateCustomPreference'] =
    async (form, args) => {
      try {
        const data = { ...form.data }
        const { cb } = args

        const payload = {
          name: data.name,
          prompt: data.prompt,
          is_default: data.is_default
        }

        const response = await API.patchData(
          ApiRoutes.dashboard.candidateAd.customPreference.update(
            this.selectedCustomPreferenceId
          ),
          payload
        )

        if (response.status === 200) {
          toast.success(
            alertMessages.candidateAd.AICustomPreference.updateSuccess
          )

          this.fetchCustomPreferences()
          this.setSelectedCustomPreferenceId(null)
          this.closeCustomPreferenceModal()

          cb?.()
        }
      } catch (error) {
        Logger.error(error as any)

        // @ts-ignore add types for error
        mapFormServerErrors(error?.data, form.fields)

        const errorMessage = generateErrorMessageFromFieldErrors(
          // @ts-ignore add types for error
          error?.data,
          form.fields
        )

        if (Array.isArray(errorMessage) && errorMessage.length > 0) {
          toast.error(errorMessage[0], { autoClose: 4000 })
        } else {
          toast.error(alertMessages.genericErrorMessage, { autoClose: 4000 })
        }
      }
    }

  @action
  deleteCustomPreference: ICandidateAdsStore['deleteCustomPreference'] = async (
    customPreferenceId: number
  ) => {
    try {
      await API.deleteData(
        ApiRoutes.dashboard.candidateAd.customPreference.delete(
          customPreferenceId
        )
      )

      toast.success(alertMessages.candidateAd.AICustomPreference.deleteSuccess)

      this.fetchCustomPreferences()
      this.setSelectedCustomPreferenceId(null)
      this.closeCustomPreferenceDeleteConfirmationModal()
    } catch (error) {
      Logger.error(error as any)
    }
  }

  @action
  openCustomPreferenceDeleteConfirmationModal: ICandidateAdsStore['openCustomPreferenceDeleteConfirmationModal'] =
    () => {
      this.isCustomPreferenceDeleteConfirmationModalOpen = true
    }

  @action
  closeCustomPreferenceDeleteConfirmationModal: ICandidateAdsStore['closeCustomPreferenceDeleteConfirmationModal'] =
    () => {
      this.isCustomPreferenceDeleteConfirmationModalOpen = false
    }

  @action
  setSelectedCustomPreferenceToGenerateAIAd: ICandidateAdsStore['setSelectedCustomPreferenceToGenerateAIAd'] =
    (customPreference) => {
      this.selectedCustomPreferenceToGenerateAIAd = customPreference
    }

  @action
  setOneTimeCustomPreferenceText: ICandidateAdsStore['setOneTimeCustomPreferenceText'] =
    (text) => {
      this.oneTimeCustomPreferenceText = text
    }
}

export default new CandidateAdsStore()
