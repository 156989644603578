import React, { Component, ReactNode } from 'react'
import { type SvgIconProps } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

import CandidateIcon from './icons/CandidateIcon'
import CandidateAdIcon from './icons/CandidateAdIcon'
import CandidateEventsIcon from './icons/CandidateEventsIcon'
import ReferrerIcon from './icons/ReferrersIcon'
import CompanyUsersIcon from './icons/CompanyUsersIcon'
import SmartlinksIcon from './icons/SmartlinksIcon'
import JobsIcon from './icons/JobsIcon'
import JobAdsIcon from './icons/JobAdsIcon'
import ActivityIcon from './icons/ActivityIcon'
import CommunicationIcon from './icons/CommunicationIcon'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import OfficeIcon from './icons/OfficeIcon'

export type DashboardItemNames =
  | 'candidates'
  | 'candidate ads'
  | 'candidate events'
  | 'referrers'
  | 'company users'
  | 'smartlinks'
  | 'jobs'
  | 'job ads'
  | 'activity'
  | 'communications'
  | 'offices'

type IconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const iconMapping: {
  [key in DashboardItemNames]: (
    props: IconProps
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>> | null
} = {
  candidates: CandidateIcon,
  // @ts-ignore fix type for forward ref icons
  'candidate ads': CandidateAdIcon,
  'candidate events': CandidateEventsIcon,
  referrers: ReferrerIcon,
  'company users': CompanyUsersIcon,
  smartlinks: SmartlinksIcon,
  jobs: JobsIcon,
  // @ts-ignore fix type for forward ref icons
  'job ads': JobAdsIcon,
  activity: ActivityIcon,
  communications: CommunicationIcon,
  offices: OfficeIcon
}

type DashboardIconProps = {
  theme: MUIThemeFuncReturnType
  iconName: DashboardItemNames
  iconAdditionalStyles?: React.CSSProperties
}

class DashboardIcon extends Component<DashboardIconProps> {
  static defaultProps = {
    iconAdditionalStyles: {}
  }

  render() {
    const { theme, iconName, iconAdditionalStyles } = this.props

    const Icon = iconMapping[iconName]

    if (!Icon) {
      return null
    }

    return (
      <>
        <Icon
          iconColor={theme.palette.primary1Color}
          style={{
            height: '20px',
            width: '20px',
            ...iconAdditionalStyles
          }}
        />
      </>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(DashboardIcon)
